import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchSchoolApi, updateSchoolApi } from "./../../services/school-api";

/// Fetch School Thunk
export const fetchSchool = createAsyncThunk(
  "school/fetchSchool",
  async (schoolId, { getState, rejectWithValue }) => {
    const token = getState().auth.auth.token;

    if (!token) {
      return rejectWithValue("User is not authenticated");
    }

    try {
      return await fetchSchoolApi(schoolId, token);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Update School Thunk
export const updateSchool = createAsyncThunk(
  "school/updateSchool",
  async ({ schoolId, updatedData }, { getState, rejectWithValue }) => {
    const token = getState().auth.auth.token;

    if (!token) {
      return rejectWithValue("User is not authenticated");
    }

    try {
      return await updateSchoolApi(schoolId, updatedData, token);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const schoolSlice = createSlice({
  name: "school",
  initialState: {
    school: null,
    loading: false,
    error: null,
    successMessage: null,
  },
  reducers: {
    clearState: (state) => {
      state.error = null;
      state.successMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch School Reducers
      .addCase(fetchSchool.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSchool.fulfilled, (state, action) => {
        state.loading = false;
        state.school = action.payload;
      })
      .addCase(fetchSchool.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Something went wrong";
      })

      // Update School Reducers
      .addCase(updateSchool.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(updateSchool.fulfilled, (state, action) => {
        state.loading = false;
        state.school = action.payload; // Update state with new data
        state.successMessage = "School information updated successfully";
      })
      .addCase(updateSchool.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Something went wrong";
      });
  },
});

export const { clearState } = schoolSlice.actions;
export default schoolSlice.reducer;
