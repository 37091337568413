import axios from 'axios';
import swal from "sweetalert";
import {
    loginConfirmedAction,
    Logout,
} from '../store/actions/AuthActions';
import env from './env';

export function signUp(email, password) {
    //axios call
    const postData = {
        email,
        password,
        returnSecureToken: true,
    };
    return axios.post(
        `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
        postData,
    );
}

export function login(email, password) {
    const postData = {
        email,
        password,
        id: '',
        token: '',
        expires_in: '',
        refresh_token: '',
    };
    return axios.post(
        env.base_url + 'auth',
        postData,
    );
}

export function formatError(errorResponse) {
    // Verifica se existe uma chave 'errors' e formata conforme necessário
    if (errorResponse.errors) {
        const errorMessages = errorResponse.errors.join(", ");
        swal("Oops", errorMessages, "error");
        return errorMessages; // Retorna as mensagens de erro concatenadas
    }

    // Se a chave 'message' estiver presente, exibe esse erro específico
    if (errorResponse.message) {
        swal("Oops", errorResponse.message, "error");
        return errorResponse.message; // Retorna a mensagem de erro
    }

    // Casos específicos de erros conhecidos, como 'EMAIL_EXISTS', etc.
    switch (errorResponse.error.message) {
        case 'EMAIL_EXISTS':
            swal("Oops", "Email already exists", "error");
            break;
        case 'EMAIL_NOT_FOUND':
            swal("Oops", "Email not found", "error", { button: "Try Again!" });
            break;
        case 'INVALID_PASSWORD':
            swal("Oops", "Invalid Password", "error", { button: "Try Again!" });
            break;
        case 'USER_DISABLED':
            swal("Oops", "User Disabled", "error");
            break;
        default:
            swal("Oops", "An unknown error occurred", "error");
            return 'An unknown error occurred'; // Mensagem genérica para erro desconhecido
    }
}

export function saveTokenInLocalStorage(tokenDetails) {
    tokenDetails.expire_date = new Date(
        new Date().getTime() + tokenDetails.expires_in * 1000,
    );
    localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, navigate) {
    setTimeout(() => {
        //dispatch(Logout(history));
        dispatch(Logout(navigate));
    }, timer);
}

export function checkAutoLogin(dispatch, navigate) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    let tokenDetails = '';
    if (!tokenDetailsString) {
        dispatch(Logout(navigate));
		return;
    }

    tokenDetails = JSON.parse(tokenDetailsString);
    let expireDate = new Date(tokenDetails.expire_date);
    let todaysDate = new Date();

    if (todaysDate > expireDate) {
        dispatch(Logout(navigate));
        return;
    }
		
    dispatch(loginConfirmedAction(tokenDetails));
	
    const timer = expireDate.getTime() - todaysDate.getTime();
    runLogoutTimer(dispatch, timer, navigate);
}

export function isLogin() {
    const tokenDetailsString = localStorage.getItem('userDetails');

    if (tokenDetailsString) {
        return true;
    }else{
        return false;
    }
}
