import env from "./env";

// Get extracurricular

export const fetchExtracurricular = async (schoolId, token) => {
  const response = await fetch(`${env.base_url}schools/${schoolId}/extracurriculars`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.errors || "Failed to fetch school information");
  }

  const { data } = await response.json();
  return data;
};

// Edit extracurricular

export const updateExtracurricular = async (schoolId, extracurricularId, updatedData, token) => {
  try {
    const response = await fetch(
      `${env.base_url}schools/${schoolId}/extracurriculars/${extracurricularId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedData),
      }
    );

    const responseJson = await response.json();
    console.log("Resposta da API:", responseJson);

    if (!response.ok) {
      console.error("Erro da API:", responseJson);
      throw new Error(responseJson.errors || "Falha ao atualizar a classe.");
    }

    return responseJson.data;
  } catch (error) {
    console.error("Erro ao chamar updateClassApi:", error);
    throw error;
  }
};

// Add Extracurricular

export const addExtracurricular = async (schoolId, extracurricularData, token) => {
  try {
    const response = await fetch(`${env.base_url}schools/${schoolId}/extracurriculars`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(extracurricularData),
    });

    const responseJson = await response.json();
    console.log("Resposta da API:", responseJson);

    if (!response.ok) {
      console.error("Erro da API:", responseJson);
      throw new Error(responseJson.errors || "Falha ao adicionar a classe.");
    }

    return responseJson.data;
  } catch (error) {
    console.error("Erro ao chamar addClassApi:", error);
    throw error;
  }
};

// Delete extracurricular
export const deleteExtracurricular = async (schoolId, extracurricularId, token) => {
  try {
    const response = await fetch(
      `${env.base_url}schools/${schoolId}/extracurriculars/${extracurricularId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const responseJson = await response.json();
    console.log("Resposta da API:", responseJson);

    if (!response.ok) {  
      console.error("Erro da API:", responseJson);
      throw new Error(responseJson.errors || "Falha ao deletar a classe.");
    }

    return responseJson.data;
  } catch (error) {
    console.error("Erro ao chamar deleteClassApi:", error);
    throw error;
  }
};
