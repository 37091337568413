const initialState = {
  gallery: [],
  loading: false,
  error: null,
};

export const galleryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_GALLERY_REQUEST":
      return { ...state, loading: true, error: null };

    case "FETCH_GALLERY_SUCCESS":
      return { ...state, gallery: action.payload, loading: false };

    case "FETCH_GALLERY_FAILURE":
      return { ...state, error: action.payload, loading: false };

    case "ADD_PICTURE_REQUEST":
      return { ...state, loading: true, error: null };

    case "ADD_PICTURE_SUCCESS":
      return {
        ...state,
        gallery: [...state.gallery, action.payload],
        loading: false,
      };

    case "ADD_PICTURE_FAILURE":
      return { ...state, error: action.payload, loading: false };

    case "DELETE_PICTURE_REQUEST":
      return { ...state, loading: true, error: null };

    case "DELETE_PICTURE_SUCCESS":
      return {
        ...state,
        gallery: state.gallery.filter(
          (picture) => picture.id !== action.payload
        ),
        loading: false,
      };

    case "DELETE_PICTURE_FAILURE":
      return { ...state, error: action.payload, loading: false };

    default:
      return state;
  }
};
