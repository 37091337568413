import env from "./env";

export const fetchGalleryApi = async (schoolId, token) => {
  const response = await fetch(`${env.base_url}schools/${schoolId}/pictures`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.errors || "Failed to fetch school information");
  }

  const { data } = await response.json();
  return data;
};

// add picture
export const addPictureApi = async (schoolId, pictureData, token) => {
  try {

    if (pictureData.image.size > 2048 * 1024) {  // 2048 KB (2MB)
        throw new Error("File size exceeds the 2MB limit.");
      }
  
    const formData = new FormData();
    formData.append("image", pictureData.image); // Adiciona a imagem ao FormData

    const response = await fetch(
      `${env.base_url}schools/${schoolId}/pictures`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`, // Sem "Content-Type", o navegador define automaticamente
        },
        body: formData,
      }
    );

    const { message, errors } = await response.json();

    if (response.ok) {
      return { success: true, message };
    } else {
      return { success: false, errors: errors || "Failed to add picture" };
    }
  } catch (error) {
    console.error("Erro ao chamar addPictureApi:", error);
    return { success: false, errors: error.message };
  }
};

export const deletePictureApi = async (schoolId, pictureId, token) => {
  try {
    const response = await fetch(
      `${env.base_url}schools/${schoolId}/pictures/${pictureId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const { message, errors } = await response.json();

    if (response.ok) {
      return message;
    } else {
      throw new Error(errors || "Failed to delete picture");
    }
  } catch (error) {
    console.error("Erro ao chamar deleteClassApi:", error);
    throw error;
  }
};
