import React, { useState, Fragment, useEffect } from "react";
import { Row, Col, Card, Table, Modal, Button, Form } from "react-bootstrap";
import {
  fetchExtracurricular,
  updateExtracurricular,
  addExtracurricular,
  deleteExtracurricular,
} from "../../../../services/extracurricular-api";
import { useSelector } from "react-redux";
import PageTitle from "../../../layouts/PageTitle";

const Extracurricular = () => {
  const schoolId = useSelector((state) => state.auth.auth.school_id);
  const token = useSelector((state) => state.auth.auth.token);
  const [extracurricular, setExtracurricular] = useState([]);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedExtracurricular, setSelectedExtracurricular] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    price: "",
  });

  const fetchextracurricular = async () => {
    try {
      const data = await fetchExtracurricular(schoolId, token);
      setExtracurricular(data);
    } catch (err) {
      setError(err.message);
    }
  };
  useEffect(() => {
    if (schoolId && token) fetchextracurricular();
  }, [schoolId, token]);

  const handleEdit = (ext = null) => {
    // Verifica se é para editar ou adicionar
    setSelectedExtracurricular(ext);
    setFormData({
      name: ext ? ext.name : "",
      price: ext ? ext.price : "",
      
    });
    setShowModal(true);
  };
  

  const handleSave = async () => {
    if (!formData.name || !formData.price) {
      setError("Por favor, preencha todos os campos.");
      return;
    }
  
    try {
      setLoading(true);
  
      let updatedClass;
  
      if (selectedExtracurricular && selectedExtracurricular.id) {
        // Se existir uma classe selecionada, estamos editando
        updatedClass = await updateExtracurricular(
          schoolId,
          selectedExtracurricular.id,
          formData,
          token
        );
        setExtracurricular((prevextracurricular) =>
          prevextracurricular.map((ext) =>
            ext.id === selectedExtracurricular.id ? updatedClass : ext
          )
        );
      } else {
        // Se não existir uma classe selecionada, estamos adicionando uma nova
        updatedClass = await addExtracurricular(schoolId, formData, token);
        setExtracurricular((prevextracurricular) => [...prevextracurricular, updatedClass]);
      }
      
      fetchextracurricular();
      setLoading(false);
      setShowModal(false);
      setFormData({ name: "", price: ""}); // Limpar o formulário
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };
  

  const handleDelete = async (ext) => {
    // Verifique se a extracurricular possui um ID válido antes de tentar excluir
    if (!ext || !ext.id) {
      console.error("Extracurricular ID não encontrado.");
      return;
    }
  
    if (window.confirm("Tem certeza de que deseja excluir essa classe?")) {
      try {
        await deleteExtracurricular(schoolId, ext.id, token);
        setExtracurricular((prevextracurricular) => prevextracurricular.filter((c) => c.id !== ext.id));
      } catch (err) {
        setError(err.message);
      }
    }
  };

  return (
    <Fragment>
      <PageTitle activeMenu="Table" motherMenu="Bootstrap" />
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <div className="d-flex align-items-center">
                <button
                  className="btn btn-primary btn-sm ms-auto"
                  onClick={() => handleEdit()}
                >
                  + Adicionar
                </button>
              </div>
            </Card.Header>
            <Card.Body>
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Preco</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                
                  <tbody>
                    {extracurricular.length > 0 ? (
                      extracurricular.map((ext, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{ext?.name}</td>
                          <td>{ext?.price}</td>
                          <td>
                            <div className="d-flex">
                              <button
                                className="btn btn-primary shadow btn-xs sharp me-1"
                                onClick={() => handleEdit(ext)}
                              >
                                <i className="fas fa-pencil-alt"></i>
                              </button>
                              <button
                                className="btn btn-danger shadow btn-xs sharp"
                                onClick={() => handleDelete(ext)}
                              >
                                <i className="fa fa-trash"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" className="text-center">
                          No extracurricular found.
                        </td>
                      </tr>
                    )}
                  </tbody>
               
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Modal for Adding or Editing */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedExtracurricular ? "Editar Classe" : "Adicionar Displina extracurricular"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <p className="text-danger">{error}</p>}
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Nome</Form.Label>
              <Form.Control
                type="text"
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formAmount" className="mt-3">
              <Form.Label>Preco</Form.Label>
              <Form.Control
                type="number"
                value={formData.price}
                onChange={(e) =>
                  setFormData({ ...formData, price: e.target.value })
                }
              />
            </Form.Group>
           
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSave}>
            {loading ? "Salvando..." : "Salvar"}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default Extracurricular;
