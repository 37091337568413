import React, { useState, Fragment, useEffect } from "react";
import { Row, Col, Card, Table, Modal, Button, Form } from "react-bootstrap";
import {
  fetchSpecialNeeds,
  updateSpecialNeeds,
  addSpecialNeeds,
  deleteSpecialNeeds,
} from "../../../../services/specialNeeds-api";
import { useSelector } from "react-redux";
import PageTitle from "../../../layouts/PageTitle";

const SpecialNeeds = () => {
  const schoolId = useSelector((state) => state.auth.auth.school_id);
  const token = useSelector((state) => state.auth.auth.token);
  const [specialNeeds, setSpecialNeeds] = useState([]);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedSpecialNeeds, setSelectedSpecialNeeds] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    price: "",
  });
  const fetchSpecial = async () => {
    try {
      const data = await fetchSpecialNeeds(schoolId, token);
      setSpecialNeeds(data);
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
   
    if (schoolId && token) fetchSpecial();
  }, [schoolId, token]);

  const handleEdit = (ext = null) => {
    // Verifica se é para editar ou adicionar
    setSelectedSpecialNeeds(ext);
    setFormData({
      name: ext ? ext.name : "",
      price: ext ? ext.price : "",
    });
    setShowModal(true);
  };

  const handleSave = async () => {
    if (!formData.name || !formData.price) {
      setError("Por favor, preencha todos os campos.");
      return;
    }

    try {
      setLoading(true);

      let updatedClass;

      if (selectedSpecialNeeds && selectedSpecialNeeds.id) {
        // Se existir uma classe selecionada, estamos editando
        updatedClass = await updateSpecialNeeds(
          schoolId,
          selectedSpecialNeeds.id,
          formData,
          token
        );
        setSpecialNeeds((prevSpecialNeeds) =>
          prevSpecialNeeds.map((ext) =>
            ext.id === selectedSpecialNeeds.id ? updatedClass : ext
          )
        );
      } else {
        // Se não existir uma classe selecionada, estamos adicionando uma nova
        updatedClass = await addSpecialNeeds(schoolId, formData, token);
        setSpecialNeeds((prevSpecialNeeds) => [
          ...prevSpecialNeeds,
          updatedClass,
        ]);
      }
      
      fetchSpecial();
      setLoading(false);
      setShowModal(false);
      setFormData({ name: "", price: "" }); // Limpar o formulário
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const handleDelete = async (ext) => {
    // Verifique se a SpecialNeeds possui um ID válido antes de tentar excluir
    if (!ext || !ext.id) {
      console.error("SpecialNeeds ID não encontrado.");
      return;
    }

    if (window.confirm("Tem certeza de que deseja excluir essa classe?")) {
      try {
        await deleteSpecialNeeds(schoolId, ext.id, token);
        setSpecialNeeds((prevSpecialNeeds) =>
          prevSpecialNeeds.filter((c) => c.id !== ext.id)
        );
      } catch (err) {
        setError(err.message);
      }
    }
  };

  return (
    <Fragment>
      <PageTitle activeMenu="Table" motherMenu="Bootstrap" />
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <div className="d-flex align-items-center">
                <button
                  className="btn btn-primary btn-sm ms-auto"
                  onClick={() => handleEdit()}
                >
                  + Adicionar
                </button>
              </div>
            </Card.Header>
            <Card.Body>
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Preco</th>
                    <th>Ações</th>
                  </tr>
                </thead>
               
                  <tbody>
                    {specialNeeds.length > 0 ? (
                      specialNeeds.map((ext, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{ext?.name}</td>
                          <td>{ext?.price}</td>
                          <td>
                            <div className="d-flex">
                              <button
                                className="btn btn-primary shadow btn-xs sharp me-1"
                                onClick={() => handleEdit(ext)}
                              >
                                <i className="fas fa-pencil-alt"></i>
                              </button>
                              <button
                                className="btn btn-danger shadow btn-xs sharp"
                                onClick={() => handleDelete(ext)}
                              >
                                <i className="fa fa-trash"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" className="text-center">
                          No SpecialNeeds found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Modal for Adding or Editing */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedSpecialNeeds ? "Editar Classe" : "Adicionar Classe"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <p className="text-danger">{error}</p>}
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Nome</Form.Label>
              <Form.Control
                type="text"
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formAmount" className="mt-3">
              <Form.Label>Preco</Form.Label>
              <Form.Control
                type="number"
                value={formData.price}
                onChange={(e) =>
                  setFormData({ ...formData, price: e.target.value })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSave}>
            {loading ? "Salvando..." : "Salvar"}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default SpecialNeeds;
