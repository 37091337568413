import React, { useState, useEffect } from "react";
import "react-quill/dist/quill.snow.css";
import "react-dropzone-uploader/dist/styles.css";
import { ToastContainer, toast } from "react-toastify";
import env from "../../../../services/env";
import { useSelector } from "react-redux";

const notify = ({ type, message, title, icon }) => {
  const options = {
    position: "bottom-right", // Default position
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    icon: icon, // Add icon if provided
    title: title, // Add title if provided
  };

  switch (type) {
    case "success":
      toast.success(message ? message : title, options);
      break;
    case "warn":
      toast.warn(message, options);
      break;
    case "error":
      toast.error(message ? message : title, options);
      break;
    case "info":
      toast.info(message, options);
      break;
    default:
      toast.info(message, options); // Default to info
  }
};

const EditAccount = () => {

  // Access schoolId from the Redux state
  const token = useSelector((state) => state.auth.auth.token);
  const [userProfile, setUserProfile] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [userSecurityData, setUserSecurityData] = useState({
    password: "",
    old_password: "",
  });
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(null); // Para controlar a mensagem de verificação

  const handleConfirmPassword = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    setPasswordMatch(userSecurityData.password === value);
  };

  const fetchUserProfile = async () => {
    try {
      const response = await fetch(`${env.base_url}accounts`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      if (response.ok) {
        const { data } = await response.json();
        setUserProfile(data);
      } else {
        console.log("Erro ao buscar perfil");
      }
    } catch (error) {
      console.error("Erro ao buscar perfil:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Função para buscar dados do perfil
    fetchUserProfile();
  }, []);

  const handleUpload = async (file) => {
    if (!file) return;
  
    setUploading(true);
    const formData = new FormData();
    formData.append("picture", file);
  
    try {
      const response = await fetch(`${env.base_url}accounts/profile-picture`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
  
      if (response.ok) {
        const { data, message } = await response.json();
        notify({ type: "success", message: message });
        setUserProfile((prevProfile) => ({
          ...prevProfile,
          full_picture_url: data.full_picture_url, // Atualize o campo correto
        }));
      } else {
        const { errors } = await response.json();
        notify({ type: "error", message: errors });
      }

      fetchUserProfile();
    } catch (error) {
      console.error("Erro ao enviar imagem:", error);
      notify({ type: "error", message: "Erro ao fazer o upload da imagem." });
    } finally {
      setUploading(false);
    }
  };
  

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setUserProfile((prevProfile) => ({
          ...prevProfile,
          full_picture_url: reader.result, // Mostra a foto temporariamente no UI
        }));
      };
      reader.readAsDataURL(file);
  
      // Faz o upload após a leitura
      handleUpload(file);
    }
  };  

  const handleChangeSecurity = (e) => {
    const { name, value } = e.target;
    setUserSecurityData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "password") {
      setPasswordMatch(value === confirmPassword);
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    if (!passwordMatch) {
      notify({
        type: "error",
        message: "As senhas não coincidem",
      });
      return;
    }
    const token = localStorage.getItem("token");
    setLoading(true);
    try {
      const responseSecurity = await fetch(
        `${env.base_url}accounts/change-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(userSecurityData),
        }
      );
      const { message, errors } = await responseSecurity.json();
      if (responseSecurity.ok) {
        notify({ type: "success", message: message });
        setUserSecurityData({
          password: "",
          old_password: "",
        });
        setConfirmPassword("");
        setLoading(false);
      } else {
        notify({ type: "error", message: errors });
        setLoading(false);
      }
    } catch (error) {
      console.error("Erro ao atualizar senha:", error);
      setLoading(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="row">
        <div className="col-xl-4 col-lg-5">
          <div className="clearfix">
            <div className="card card-bx profile-card author-profile m-b30">
              <div className="card-body">
                <div className="p-5">
                  <div className="author-profile text-center">
                    <div className="author-media">
                      <img style={{ width: "150px", height: "150px", objectFit: "cover" }} src={userProfile.full_picture_url} alt="" />
                      <div
                        className="upload-link"
                        title=""
                        data-toggle="tooltip"
                        data-placement="right"
                        data-original-title="update"
                      >
                        <input
                          type="file"
                          className="update-flie"
                          onChange={handlePhotoChange}
                        />
                        <i className="fa fa-camera"></i>
                      </div>
                    </div>
                    <div className="author-info">
                      <h6 className="title">{userProfile.first_name} {userProfile.last_name}</h6>
                      <span>{userProfile.email}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-8 col-lg-9">
          <div className="card profile-card card-bx ">
            <div className="card-header">
              <h6 className="title">Atualizar dados do centro</h6>
            </div>
            <form className="profile-form" onSubmit={handleChangePassword}>
              <div className="card-body">
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label className="form-label">Palavra-chave antiga</label>
                    <input
                      type="email"
                      placeholder="Palavra-chave antiga"
                      className="form-control"
                      name="old_password"
                      value={userSecurityData.old_password}
                      onChange={handleChangeSecurity}
                    />
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label className="form-label">Nova Palavra-chave</label>
                    <input
                      type="password"
                      placeholder="Palavra-chave"
                      className="form-control"
                      name="password"
                      value={userSecurityData.password}
                      onChange={handleChangeSecurity}
                    />
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label className="form-label">Confimar Nova Palavra-chave</label>
                    <input
                      type="password"
                      placeholder="Palavra-chave"
                      className="form-control"
                      name="password"
                      value={confirmPassword}
                      onChange={handleConfirmPassword}
                    />
                  </div>
                </div>
              </div>
              <div className="card-footer align-items-center d-flex">
                <button
                  type="submit"
                  disabled={loading}
                  className="btn btn-primary"
                >
                  {loading ? (
                    <span className="spinner-border spinner-border-sm"></span>
                  ) : (
                    "Atualizar"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditAccount;
