import env from "../services/env";

// Fetch School Information API
export const fetchSchoolApi = async (schoolId, token) => {
  const response = await fetch(`${env.base_url}schools/${schoolId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || "Failed to fetch school information");
  }

  const { data } = await response.json();
  return data;
};

// Update School Information API
export const updateSchoolApi = async (schoolId, updatedData, token) => {
  const response = await fetch(`${env.base_url}schools/${schoolId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(updatedData),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || "Failed to update school information");
  }

  const { data } = await response.json();
  return data;
};
