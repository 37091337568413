import React, { useState } from "react";
import { Link } from "react-router-dom";
// image
import FinalizeIcon from "../../images/Finalize_MainIcon.png"
import env from "../../services/env";
const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage("");
    setError("");

    try {
      const response = await fetch(`${env.base_url}auth/account/forgot-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ email }),
      });
      console.log(response);

      if (response.ok) {
        
        setMessage(
          "Um e-mail com instruções para redefinir sua senha foi enviado."
        );
      } else {
        const errorData = await response.json();
        
        setError(
          errorData.errors ||
            "Falha ao enviar o e-mail de redefinição de senha."
        );
      }
    } catch (err) {
      setError(
        "Ocorreu um erro ao enviar o e-mail de redefinição de senha. Tente novamente mais tarde."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="authincation h-100">
      <div className="container">
        {" "}
        <div className="row justify-content-center align-items-center" style={{ height: "100vh" }}>
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row ">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/dashboard">
                        <img src={FinalizeIcon} alt=""  style={{ width: "200px" }}/>
                      </Link>
                    </div>
                    <h4 className="text-center mb-4 ">Forgot Password</h4>
                    <form onSubmit={(e) => handleSubmit(e)}>
                      <div className="form-group">
                        <label className="">
                          <strong>Email</strong>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                        <span className="text-danger">{error}</span>
                      </div>
                      <div className="text-center mt-4">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                          {loading ? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            "Enviar"
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
