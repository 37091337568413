import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import PostsReducer, { toggleMenu } from "./reducers/PostsReducer";
import { AuthReducer } from "./reducers/AuthReducer";
import todoReducers from "./reducers/Reducers";
import { galleryReducer } from "./reducers/GalleryReducer";
import schoolSlice from "./slices/schoolSlice";

// Middleware for Redux
const middleware = applyMiddleware(thunk);

// Enable Redux DevTools Extension if available
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Combine all reducers
const reducers = combineReducers({
  sideMenu: toggleMenu,
  posts: PostsReducer,
  auth: AuthReducer,
  todoReducers,
  gallery: galleryReducer,
  school: schoolSlice,
});

export const store = createStore(reducers, composeEnhancers(middleware));
