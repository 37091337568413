import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import PageTitle from "../../../layouts/PageTitle";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import env from "../../../../services/env";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { ToastContainer, toast } from "react-toastify";

const notify = ({ type, message, title, icon }) => {
  const options = {
    position: "bottom-right", // Default position
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    icon: icon, // Add icon if provided
    title: title, // Add title if provided
  };

  switch (type) {
    case "success":
      toast.success(message ? message : title, options);
      break;
    case "warn":
      toast.warn(message, options);
      break;
    case "error":
      toast.error(message ? message : title, options);
      break;
    case "info":
      toast.info(message, options);
      break;
    default:
      toast.info(message, options); // Default to info
  }
};

const PostDetails = () => {
  const schoolId = useSelector((state) => state.auth.auth.school_id);
  const token = useSelector((state) => state.auth.auth.token);
  const [policies, setPolicies] = useState([]);
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  const [updateName, setUpdateName] = useState("");
  const [updateDescription, setUpdateDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(null);
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [expandedPolicyIds, setExpandedPolicyIds] = useState([]);

  const toggleReadMore = (id) => {
    setExpandedPolicyIds((prev) =>
      prev.includes(id)
        ? prev.filter((policyId) => policyId !== id)
        : [...prev, id]
    );
  };

  const isPolicyExpanded = (id) => expandedPolicyIds.includes(id);

  const handleDescriptionChange = (value) => {
    setDescription(value);
  };

  const handleNameChange = (value) => {
    setName(value);
  };

  useEffect(() => {
    const fetchPoliciesData = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${env.base_url}schools/${schoolId}/policies`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.ok) {
          const { data } = await response.json();
          setPolicies(data);
          setLoading(false);
        } else {
          const errorData = await response.json();
          notify("error", errorData.errors);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching policies:", error);
        setLoading(false);
      }
    };
    fetchPoliciesData();
  }, [schoolId, token]);

  //Function to add new policy
  const handleaddNewPolicy = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await fetch(
        `${env.base_url}schools/${schoolId}/policies`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ name, description }),
        }
      );
      if (response.ok) {
        const { data, message } = await response.json();
        setLoading(false);
        notify({ type: "success", message: message });

        // Atualiza a lista de políticas com a nova política
        setPolicies((prevPolicies) => [data, ...prevPolicies]); // Adiciona a política no início da lista

        // Limpa os campos de nome e descrição após adicionar
        setName("");
        setDescription("");

        // Recarrega as políticas para garantir que os dados estejam atualizados
        const updatedPoliciesResponse = await fetch(
          `${env.base_url}schools/${schoolId}/policies`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (updatedPoliciesResponse.ok) {
          const { data } = await updatedPoliciesResponse.json();
          setPolicies(data); // Atualiza com as políticas mais recentes
        } else {
          const errorData = await updatedPoliciesResponse.json();
          notify({ type: "error", message: errorData.errors });
        }
      } else {
        const errorData = await response.json();
        setLoading(false);
        notify({ type: "error", message: errorData.errors });
      }
    } catch (error) {
      setLoading(false); // Certifique-se de desabilitar o carregamento em caso de erro
      console.error("Error adding policy:", error);
    }
  };

  // Função para atualizar a política
  const handleUpdatePolicy = async (e) => {
    e.preventDefault();
    try {
      setLoadingUpdate(true);
      const response = await fetch(
        `${env.base_url}schools/${schoolId}/policies/${selectedPolicy.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            name: updateName,
            description: updateDescription,
          }),
        }
      );
      if (response.ok) {
        const { message } = await response.json();
        setLoadingUpdate(false);
        notify({ type: "success", message: message });

        // Update the policy list
        setPolicies((prevPolicies) =>
          prevPolicies.map((policy) =>
            policy.id === selectedPolicy.id
              ? { ...policy, name: updateName, description: updateDescription }
              : policy
          )
        );

        // Clear the form and hide it
        setShowUpdateForm(false);
        setSelectedPolicy(null);
        setUpdateName("");
        setUpdateDescription("");
      } else {
        const errorData = await response.json();
        setLoadingUpdate(false);
        notify({ type: "error", message: errorData.errors });
      }
    } catch (error) {
      setLoadingUpdate(false);
      console.error("Error updating policy:", error);
    }
  };

  // Função chamada ao clicar no botão de editar
  const handleEditPolicy = (policy) => {
    setShowUpdateForm(policy.id); // Show the form for the selected policy
    setUpdateName(policy.name); // Populate name field
    setUpdateDescription(policy.description); // Populate description field
  };

  // Function to delete policy
  const handleDeletePolicy = async (policyId) => {
    try {
      const response = await fetch(
        `${env.base_url}schools/${schoolId}/policies/${policyId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.ok) {
        const { message } = await response.json();
        notify({ type: "success", message: message });
        setPolicies((prevPolicies) =>
          prevPolicies.filter((policy) => policy.id !== policyId)
        );
      } else {
        const errorData = await response.json();
        notify({ type: "error", message: errorData.errors });
      }
    } catch (error) {
      console.error("Error deleting policy:", error);
    }
  };

  return (
    <div>
      <PageTitle
        activeMenu="Post Details"
        motherMenu="Advanced"
        pageContent="Post Details"
      />
      <ToastContainer />

      <div className="row">
        <div className="col-xl-4">
          <div className="card p-4">
            <h4 className="text-primary mb-3">Adicione uma nova Política</h4>
            <form onSubmit={handleaddNewPolicy}>
              <div className="form-group mb-3">
                <label htmlFor="name" className="text-black font-w600">
                  Nome <span className="required">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(e) => handleNameChange(e.target.value)}
                  placeholder="Nome"
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="description" className="text-black font-w600">
                  Descrição <span className="required">*</span>
                </label>
                <ReactQuill
                  theme="snow"
                  value={description}
                  onChange={handleDescriptionChange}
                />
              </div>
              <button type="submit" className="btn btn-primary">
                {loading ? "Adicionando..." : "Adicionar"}
              </button>
            </form>
          </div>
        </div>

        <div className="col-xl-8">
          <div className="card">
            <div className="card-body">
              {loading ? (
                <>
                  {[...Array(2)].map((_, index) => (
                    <div key={index} className="mb-4">
                      <h2>
                        <Skeleton width={300} />
                      </h2>
                      <p>
                        <Skeleton count={5} />
                      </p>
                    </div>
                  ))}
                </>
              ) : policies?.length > 0 ? (
                policies.map((policy) =>
                  policy?.id ? (
                    <section key={policy.id} className="mb-5">
                      <h2>{policy.name}</h2>
                      <p>
                        {isPolicyExpanded(policy.id) ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: policy.description || "",
                            }}
                          />
                        ) : (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: policy.description.slice(0, 300) + "...",
                            }}
                          />
                        )}
                      </p>
                      <Button
                        variant="link"
                        className="text-primary"
                        onClick={() => toggleReadMore(policy.id)}
                      >
                        {isPolicyExpanded(policy.id) ? "Ler menos" : "Ler mais"}
                      </Button>
                      <div className="mt-3">
                        <Button
                          onClick={() => handleEditPolicy(policy)} // Pass policy to handler
                          className="btn btn-outline-primary me-2"
                        >
                          Editar
                        </Button>
                        <Button
                          onClick={() => handleDeletePolicy(policy.id)}
                          className="btn btn-outline-danger"
                        >
                          Apagar
                        </Button>
                      </div>
                      {showUpdateForm === policy.id && (
                        <div className="mt-5">
                          <hr />
                          <h4 className="text-primary mb-3">
                            Editar Política Selecionada
                          </h4>
                          <form onSubmit={handleUpdatePolicy}>
                            <div className="form-group mb-3">
                              <label
                                htmlFor="name"
                                className="text-black font-w600"
                              >
                                Nome <span className="required">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={updateName} // Bind to state
                                onChange={(e) => setUpdateName(e.target.value)} // Update state on change
                              />
                            </div>
                            <div className="form-group mb-3">
                              <label
                                htmlFor="description"
                                className="text-black font-w600"
                              >
                                Descrição <span className="required">*</span>
                              </label>
                              <ReactQuill
                                theme="snow"
                                value={updateDescription} // Bind to state
                                onChange={(value) =>
                                  setUpdateDescription(value)
                                } // Update state on change
                              />
                            </div>
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={loadingUpdate}
                            >
                              {loadingUpdate ? "Atualizando..." : "Atualizar"}
                            </button>
                            <button
                              type="button"
                              className="btn btn-danger ms-1"
                              onClick={() => setShowUpdateForm(null)} // Hide the form
                            >
                              Cancelar
                            </button>
                          </form>
                        </div>
                      )}
                    </section>
                  ) : null
                )
              ) : (
                <div className="text-center">
                  <p>Nenhuma política encontrada.</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostDetails;
