import React, { Fragment, useEffect, useState } from "react";
import { Tab, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { fetchSchool } from "../../../../store/slices/schoolSlice";
import { useDispatch, useSelector } from "react-redux";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import StarRating from "./components/StarRating";

// import styles
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import PageTitle from "../../../layouts/PageTitle";
import { fetchGallery } from "../../../../store/actions/GalleryActions";
import { fetchGalleryApi } from "../../../../services/gallery-api";

const AppProfile = () => {
 
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [gallery, setGallery] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // Access schoolId from the Redux state
  const schoolId = useSelector((state) => state.auth.auth.school_id);
  const token = useSelector((state) => state.auth.auth.token);

  useEffect(() => {
    if (schoolId) {
      dispatch(fetchSchool(schoolId));
    }
  }, [dispatch, schoolId]);

  const fetchGallery = async () => {
      try {
        const data = await fetchGalleryApi(schoolId, token);
        setGallery(data);
      } catch (err) {
        setError(err.message);
      }
    };
    
    useEffect(() => {
      if (schoolId && token) fetchGallery();
    }, [schoolId, token]);

  const {
    school,
    loading: schoolLoading,
    error: schoolError,
  } = useSelector((state) => state.school);
  

  return (
    <Fragment>
      <PageTitle activeMenu="Perfil" motherMenu="App" />
      {school && (
        <div className="row">
          <div className="col-lg-12">
            <div className="profile card card-body px-3 pt-3 pb-0">
              <div className="profile-head">
                <div className="photo-content ">
                  {schoolLoading ? (
                    <Skeleton count={1} height={200} />
                  ) : (
                    <div
                      className="cover-photo rounded"
                      style={{
                        backgroundImage: `url(${school.full_cover_url})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    ></div>
                  )}
                </div>
                <div className="profile-info">
                  <div className="profile-photo">
                    {schoolLoading ? (
                      <Skeleton
                        circle
                        height={100}
                        width={100}
                        count={1}
                        className="img-fluid rounded-circle bg-white"
                      />
                    ) : (
                      <img
                        src={school.full_logo_url}
                        style={{objectFit: "cover", width: "100px", height: "100px"}}
                        className="img-fluid rounded-circle bg-white"
                        alt="profile"
                      />
                    )}
                  </div>
                  <div className="profile-details">
                    <div className="profile-name px-3 pt-2">
                      {schoolLoading ? (
                        <Skeleton width={200} height={30} />
                      ) : (
                        <h4 className="text-primary mb-0">{school?.name}</h4>
                      )}
                      <div className="d-flex">
                        <p>
                          <span className="fw-bold me-2">Estado:</span>{" "}
                          {schoolLoading ? (
                            <Skeleton width={50} />
                          ) : (
                            school?.status
                          )}
                        </p>
                        <p className="ms-5">
                          <span className="fw-bold me-2">
                            Última modificação:
                          </span>{" "}
                          {schoolLoading ? (
                            <Skeleton width={100} />
                          ) : (
                            school?.last_update
                          )}
                        </p>
                      </div>
                    </div>

                    {/* Dropdown */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {school && (
        <div className="row">
          <div className="col-xl-4">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="profile-statistics">
                      <h5 className="text-primary">Detalhes Operacionaiss</h5>
                      <div className="">
                        <div className="row">
                          <div className="col">
                            <span>Abre ás</span>
                            {schoolLoading ? (
                              <Skeleton width={50} />
                            ) : (
                              <h3 className="m-b-0">{school.open_time}</h3>
                            )}
                          </div>
                          <div className="col">
                            <span>Fecha ás</span>
                            {schoolLoading ? (
                              <Skeleton width={50} />
                            ) : (
                              <h3 className="m-b-0">{school.close_time}</h3>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header border-0 pb-0">
                    <h5 className="text-primary">Detalhes Educacionais</h5>
                  </div>

                  <div className="card-body pt-3">
                    <div className="row mb-2">
                      <div className="col-6">
                        <h5 className="f-w-500">
                          {" "}
                          Classificação<span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-6">
                        <StarRating score={school.score} />
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-6">
                        <h5 className="f-w-500">
                          {" "}
                          Tipo de educação<span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-6">
                        {schoolLoading ? (
                          <Skeleton width={50} />
                        ) : (
                          <span>{school.education_type}</span>
                        )}
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-6">
                        <h5 className="f-w-500">
                          {" "}
                          Linguas Oferecidas
                          <span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-6">
                        <ul>
                          {schoolLoading ? (
                            <Skeleton width={50} />
                          ) : (
                            school.languages.map((language, index) => (
                              <li key={index}>{language}</li>
                            ))
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-6">
                        <h5 className="f-w-500">
                          {" "}
                          Capacidade<span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-6">
                        {schoolLoading ? (
                          <Skeleton width={50} />
                        ) : (
                          <span>{school.capacity}</span>
                        )}
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-6">
                        <h5 className="f-w-500">
                          {" "}
                          No de salas<span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-6">
                        {schoolLoading ? (
                          <Skeleton width={50} />
                        ) : (
                          <span>{school.classrooms}</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header border-0 pb-0">
                    <h5 className="text-primary">Detalhes de Inscrição</h5>
                  </div>

                  <div className="card-body pt-3">
                    <div className="row mb-2">
                      <div className="col-6">
                        <h5 className="f-w-500">
                          {" "}
                          Valor da Inscrição
                          <span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-6">
                        {schoolLoading ? (
                          <Skeleton width={50} />
                        ) : (
                          <span>{school.enrollment_fee}</span>
                        )}
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-6">
                        <h5 className="f-w-500">
                          {" "}
                          Data de Inscrição<span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-6">
                        {schoolLoading ? (
                          <Skeleton width={50} />
                        ) : (
                          <span>{school.enrollment_start_date}</span>
                        )}
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-6">
                        <h5 className="f-w-500">
                          {" "}
                          Última data de Inscrição
                          <span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-6">
                        {schoolLoading ? (
                          <Skeleton width={50} />
                        ) : (
                          <span>{school.enrollment_end_date}</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header border-0 pb-0">
                    <h5 className="text-primary ">Galeria</h5>
                  </div>
                  <div className="card-body pt-3">
                    <div className="profile-interest">
                      {loading ? (
                        <Skeleton count={6} height={150} width="100%" />
                      ) : (
                        <div className="row sp4">
                          {gallery.map((item, index) => (
                            <div
                              className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col mb-1"
                              key={item?.id}
                            >
                              <img
                                src={item?.full_image_url}
                                style={{ width: "100%", cursor: "pointer" }}
                                alt="gallery"
                                onClick={() => {
                                  setPhotoIndex(index);
                                  setIsOpen(true);
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      )}

                      {isOpen && (
                        <Lightbox
                          mainSrc={gallery[photoIndex]?.full_image_url}
                          nextSrc={
                            gallery[(photoIndex + 1) % gallery.length]
                              ?.full_image_url
                          }
                          prevSrc={
                            gallery[
                              (photoIndex + gallery.length - 1) % gallery.length
                            ]?.full_image_url
                          }
                          onCloseRequest={() => setIsOpen(false)}
                          onMovePrevRequest={() =>
                            setPhotoIndex(
                              (photoIndex + gallery.length - 1) % gallery.length
                            )
                          }
                          onMoveNextRequest={() =>
                            setPhotoIndex((photoIndex + 1) % gallery.length)
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-8">
            <div className="card">
              <div className="card-body">
                <div className="profile-tab">
                  <div className="custom-tab-1">
                    <Tab.Container defaultActiveKey="About">
                      <Nav as="ul" className="nav nav-tabs">
                        <Nav.Item as="li" className="nav-item">
                          <Nav.Link to="#about-me" eventKey="About">
                            Sobre o centro
                          </Nav.Link>
                        </Nav.Item>
                        {/* Configurações */}
                        <Nav.Item as="li" className="nav-item">
                          {/* <Nav.Link to="#profile-settings" eventKey="Setting">
                            Atualizar dados de acesso
                          </Nav.Link> */}
                        </Nav.Item>
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane id="about-me" eventKey="About">
                          <div className="profile-about-me">
                            <div className="pt-4 border-bottom-1 pb-3">
                              <h4 className="text-primary">
                                Detalhes do centro
                              </h4>
                              <div
                                className="mb-2"
                                dangerouslySetInnerHTML={{
                                  __html: school.description || "",
                                }}
                              />
                            </div>
                          </div>
                          <div className="profile-lang  mb-5">
                            <h4 className="text-primary mb-2">
                              Ano de fundação
                            </h4>
                            <Link
                              to="/app-profile"
                              className="text-muted pe-3 f-s-16"
                            >
                              <i className="flag-icon flag-icon-us" />
                              {school.foundation_year}
                            </Link>
                          </div>

                          <div className="profile-personal-info mb-5">
                            <h4 className="text-primary mb-4">
                              Informações de contacto
                            </h4>
                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500">
                                  {" "}
                                  Telefone<span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-9">
                                <span>{school.phone}</span>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500">
                                  {" "}
                                  Telefone Alternativo
                                  <span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-9">
                                <span>{school.mobile}</span>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500">
                                  Email<span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-9">
                                <span>{school.email}</span>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500">
                                  Website<span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-9">
                                <span>{school.website}</span>
                              </div>
                            </div>
                          </div>

                          <div className="profile-personal-info">
                            <h4 className="text-primary mb-4">
                              Informações de endereço
                            </h4>
                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500">
                                  {" "}
                                  Localização
                                  <span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-9">
                                <span>{school.address}</span>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500">
                                  {" "}
                                  Provincia
                                  <span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-9">
                                <span>{school.province}</span>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500">
                                  Districto<span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-9">
                                <span>{school.district}</span>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>

                        <Tab.Pane id="profile-settings" eventKey="Setting">
                          <div className="pt-3">
                            <div className="settings-form">
                              <h4 className="text-primary">Dados de acesso</h4>
                              <form onSubmit={(e) => e.preventDefault()}>
                                <div className="row">
                                  <div className="form-group mb-3 col-md-6">
                                    <label className="form-label">Email</label>
                                    <input
                                      type="email"
                                      placeholder="Email"
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="form-group mb-3 col-md-6">
                                    <label className="form-label">
                                      Palavra-chave
                                    </label>
                                    <input
                                      type="password"
                                      placeholder="Palavra-chave"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                                <div className="form-group mb-3">
                                  <label className="form-label">Address</label>
                                  <input
                                    type="text"
                                    placeholder="1234 Main St"
                                    className="form-control"
                                  />
                                </div>
                                <div className="form-group mb-3">
                                  <label className="form-label">
                                    Address 2
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Apartment, studio, or floor"
                                    className="form-control"
                                  />
                                </div>
                                <div className="row">
                                  <div className="form-group mb-3 col-md-6">
                                    <label className="form-label">City</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="form-group mb-3 col-md-4">
                                    <label className="form-label">State</label>
                                    <select
                                      className="form-control"
                                      id="inputState"
                                      defaultValue="option-1"
                                    >
                                      <option value="option-1">
                                        Choose...
                                      </option>
                                      <option value="option-2">Option 1</option>
                                      <option value="option-3">Option 2</option>
                                      <option value="option-4">Option 3</option>
                                    </select>
                                  </div>
                                  <div className="form-group mb-3 col-md-2">
                                    <label className="form-label">Zip</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                                <div className="form-group mb-3">
                                  <div className="form-check custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="gridCheck"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="gridCheck"
                                    >
                                      Check me out
                                    </label>
                                  </div>
                                </div>
                                <button
                                  className="btn btn-primary"
                                  type="submit"
                                >
                                  Sign in
                                </button>
                              </form>
                            </div>
                          </div>
                        </Tab.Pane>

                        {/* Configurações */}
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default AppProfile;
