import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Button, Alert, Form } from "react-bootstrap";
import env from "../../services/env";
import FinalizeIcon from "../../images/Finalize_MainIcon.png"

const ResetPassword = () => {
  const { tokenAndEmail } = useParams();
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  // Extrair o token e o email da string tokenAndEmail
  const [token, email] = tokenAndEmail.split("&");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError("As senhas não coincidem.");
      return;
    }

    setLoading(true);
    setError("");
    setMessage("");

    try {
      const response = await fetch(
        `${env.base_url}auth/account/reset-password/${token}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            email,
            password: newPassword,
            password_confirmation: confirmPassword,
          }),
        }
      );

      if (response.ok) {
        setMessage("Sua senha foi redefinida com sucesso!");
      } else {
        const errorData = await response.json();
        setError(errorData.errors || "Falha ao redefinir a senha.");
      }
    } catch (err) {
      setError(
        "Ocorreu um erro ao redefinir a senha. Tente novamente mais tarde."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="vh-100 d-flex justify-content-center align-items-center">
      <div className="p-4 w-100" style={{ maxWidth: "400px" }}>
        <div className="d-flex justify-content-center align-items-center mb-3">
              <img
                src={FinalizeIcon}
                alt="Carregando..."
                style={{
                  width: "15%",
                  height: "auto",
                  display: "flex",
                }}
              />
            </div>
        <h2 className="text-center mb-4">Redefinir Senha</h2>
        {error && <Alert variant="danger">{error}</Alert>}
        {message && <Alert variant="success">{message}</Alert>}
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="newPassword" className="mb-3">
            <Form.Label></Form.Label>
            <Form.Control
              type="password"
              placeholder="Nova senha"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="confirmPassword" className="mb-3">
            <Form.Label></Form.Label>
            <Form.Control
              type="password"
              placeholder="Confirmar nova senha"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </Form.Group>
          <Button type="submit" className="w-100 mt-3 btn-danger">
            Confirmar
          </Button>
        </Form>
        <Button
          onClick={() => navigate("/login")}
          className="w-100 mt-3 text-white"
          style={{ backgroundColor: "gold", borderColor: "gold" }}
        >
          Voltar ao Login
        </Button>
      </div>
    </Container>
  );
};

export default ResetPassword;
