import React from "react";

const StarRating = ({ score }) => {
  const maxStars = 5; // Total number of stars

  // Create an array of stars based on the score
  const stars = Array.from({ length: maxStars }, (_, index) => {
    return index < score ? "fas fa-star" : "far fa-star";
  });

  return (
    <div className="star-rating">
      {stars.map((starClass, index) => (
        <i
          key={index}
          className={starClass}
          style={{ color: "#FFD700", marginRight: 4 }}
        ></i>
      ))}
    </div>
  );
};

export default StarRating;
